import api from './api'

export default {
  criar(body, token){
    let config = {}
    if(token){
      config = {
        headers: { 'x-access-token': `${token}`}
      };
    }else{
      config = {
        headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
      };
    }
    return api().post('/movimento/upinsert', body, config)
  },
  buscar(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/movimento/select', body, config)
  },
}