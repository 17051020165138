<template>
    <div class="wrapper">
      <div class="boxcontainer">
        <VueSignaturePad
          id="signature"
          width="100%"
          height="100%"
          ref="signaturePad"
          :options="options"
        />
      </div>
      <div class="buttons">
        <button type="button" class="btn refazer" @click="undo">Refazer</button>
        <button type="button" class="btn salvar" :disabled="confirmado" @click="save">
          <v-icon color="#f9f9f2" v-if="confirmado">mdi-check-circle</v-icon>
          <p v-if="!confirmado">Confirmar</p>
        </button>
      </div>
    </div>
</template>

<script>
export default {
  data: () => ({
    options: {
      penColor: "#000",
    },
    confirmado: false,
  }),
  methods: {
    undo() {
      this.$refs.signaturePad.clearSignature();
      this.confirmado = false
      this.$emit('input', null)
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if(!isEmpty){
        this.confirmado = true
        this.$emit('input', data)
      }
    },

  },
};
</script>

<style lang="scss" scoped>
.buttons{
  display: flex;
  @media screen and (min-width: 768px){
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .btn{
    box-shadow:  4px 4px 10px #d9d9d9;
    border-radius: 1em;
    margin-left: 1em;
    padding: .5em 1em;
    margin-bottom: .5em;
    @media screen and (min-width: 768px) {
      margin-bottom: unset;      
      margin-top: .5em;      
    }
    &.refazer{
      color: #1377f2;
    }
    &.salvar{
      background-color: #1377f2;
      color: white;
    }
  }

}
.wrapper{
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }
}
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.boxcontainer {
  width: 100%;
  height: 100%;
}

button[type="button"]{
  color: #1377f2;
  box-shadow:  4px 4px 10px #d9d9d9;
  border-radius: 1em;
  margin-left: 1em;
  padding: 1em;
}
</style>

