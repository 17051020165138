<template>
  <div class="container">
    <!-- <div class="header">
          <ul id="slider">
            <span class="slider-controls">
              <button type="button" @click="() => toLeft()">
                <v-icon>mdi-arrow-left-bold-circle-outline</v-icon>
              </button>
              <button type="button" @click="() => toRight()">
                <v-icon>mdi-arrow-right-bold-circle-outline</v-icon>
              </button>
            </span>
        <li
          v-for="etapaatual in implantacao.etapas"
          :key="etapaatual.id"
          :class="classeEtapa(etapaatual)"
          @click="selecionarEtapa(etapaatual.id)"
        >
            <v-icon color="#f9f9f2" v-if="etapaatual.concluida">
              mdi-check-circle
            </v-icon>
            {{etapaatual.nome}}
        </li>
      </ul>
    </div> -->
    <div>
      <div class="box header" v-if="etapa" :class="etapa.concluida ? 'concluida' : 'nconcluida'">
        <h1>{{etapa.nome}}</h1>
      </div>
      <div v-if="etapa && etapa.gadget !== null && etapa.gadget.idtipo === 1">
        <div v-html="etapa.gadget.texto" class="box contrato" id="box-contrato">

        </div>
        <div class="box assinatura">
          <Assinatura  v-model="assinaturaetapa" v-if="!verassinatura"/>
          <div v-if="verassinatura" class="ver-assinatura">
            <img :src="assinaturaetapa" alt="assinatura">
            <button type="button" @click="verassinatura = false">trocar assinatura</button>
          </div>
        </div>
      </div>
      <span v-if="etapa && etapa.gadget !== null && etapa.gadget.idtipo === 2">
        <!-- {{JSON.stringify(perguntas[perguntaatualidx], null, '\t')}} -->
        <div v-if="perguntas[perguntaatualidx].respostasfechadas && perguntas[perguntaatualidx].respostasfechadas.listaresposta.length > 0">
          <div class="box minheight pergunta fechada">
            <div class="field">
              <p>{{perguntas[perguntaatualidx].pergunta}}</p>
              <div v-for="opcao, idx in perguntas[perguntaatualidx].respostasfechadas.listaresposta" :key="idx" class="wrapper-opcao">
                <input type="checkbox" 
                  @change="limparOpcoes(perguntaatualidx, opcao)"
                 v-model="perguntas[perguntaatualidx].respostasfechadas.listaresposta[idx].valor"/>
                <label>{{opcao.resposta}}</label>
              </div>
            </div>
          </div>
          <div v-for="pergunta, idxextra in perguntasextrasatuais" :key="pergunta.id">
            <div v-if="pergunta.respostaaberta">
              <div class="box pergunta aberta">
                <div class="field">
                  <p>{{pergunta.pergunta}}</p>
                  <textarea  style="border-radius: 7px;border: 2px solid #666; padding: 0 .5em; " v-if="movimento && !pergunta.mascara" v-model="pergunta.respostavalor"/>
                  <textarea style="border-radius: 7px;border: 2px solid #666; padding: 0 .5em; "  v-if="!movimento && !pergunta.mascara" v-model="pergunta.valor"/>
                  
                  <input  style="border-radius: 7px;border: 2px solid #666; padding: 0 .5em; "  v-if="movimento && pergunta  && pergunta.mascara" v-mask="pergunta.mascara === 'cnpj' 
                  ? '###.###.####/##' 
                  :(pergunta.mascara === 'cpf'?'###.###.###-##'
                  :(pergunta.mascara === 'telefone'?'(##) #-####-####'
                  :(pergunta.mascara === 'data' ? '##/##/####' : null)))" v-model="pergunta.respostavalor"/>
                  
                  <input  style="border-radius: 7px;border: 2px solid #666; padding: 0 .5em; "  v-if="!movimento && pergunta  &&  pergunta.mascara" v-mask="pergunta.mascara === 'cnpj' 
                  ? '###.###.####/##' 
                  :(pergunta.mascara === 'cpf'?'###.###.###-##'
                  :(pergunta.mascara === 'telefone'?'(##) #-####-####'
                  :(pergunta.mascara === 'data' ? '##/##/####' : null)))" v-model="pergunta.valor"/>
                </div>
              </div>
            </div>
            <div v-if="pergunta.respostasfechadas && pergunta.respostasfechadas.listaresposta.length > 0">
              <div class="box pergunta fechada">
                <div class="field">
                  <p>{{pergunta.pergunta}}</p>
                  <div v-for="opcao, idx in pergunta.respostasfechadas.listaresposta" :key="idx" class="wrapper-opcao">
                    <input type="checkbox" 
                      @change="limparOpcoesPergutasExtras(idxextra, opcao)" 
                    v-model="pergunta.respostasfechadas.listaresposta[idx].valor"/>
                    <label>{{opcao.resp}}</label>
                  </div>
                </div>
              </div>
            </div>
                      <div v-if="pergunta.respostadata">
            <div class="box minheight pergunta data">
              <div class="field">
                  <p>{{pergunta.pergunta}}</p>
                  <span class="respostadata-wrapper-inputs">
                    <v-text-field
                      label="Data"
                      placeholder="Insira a data"
                      outlined
                      v-model="pergunta.valor"
                      name="data"
                      type="date"
                      dense
                      hide-details=""
                      class="input-date"
                    ></v-text-field>
                    <v-select
                      class="input-hour"
                      :items="horarios"
                      label="Horário"
                      name="horario"
                      v-model="pergunta.horario"
                      outlined
                      dense
                      hide-details
                    ></v-select>
                  </span>

                </div>
            </div>
          </div>
            
          </div>
        </div>
        <div v-if="perguntas[perguntaatualidx].respostaaberta">
            <div class="box minheight pergunta aberta" v-if="!movimento">
              <div class="field">
                  <p>{{perguntas[perguntaatualidx].pergunta}}</p>
                  <textarea style="border-radius: 7px !important; border: 2px solid #666 !important; padding: 0 .5em;" v-if="!perguntas[perguntaatualidx].mascara" v-model="perguntas[perguntaatualidx].valor" />
                  <input style="border-radius: 7px !important; border: 2px solid #666 !important; padding: 0 .5em;" v-if="perguntas[perguntaatualidx].mascara" 
                  v-mask="perguntas[perguntaatualidx].mascara === 'cnpj' 
                  ? '###.###.####/##' 
                  :(perguntas[perguntaatualidx].mascara === 'cpf'?'###.###.###-##'
                  :(perguntas[perguntaatualidx].mascara === 'telefone'?'(##) #-####-####'
                  :(perguntas[perguntaatualidx].mascara === 'data' ? '##/##/####' : null)))" v-model="perguntas[perguntaatualidx].valor"/>
                </div>
            </div>
            <div class="box minheight pergunta aberta" v-if="movimento">
              <div class="field">
                  <p>{{perguntas[perguntaatualidx].pergunta}}</p>
                  <textarea  style="border-radius: 7px !important; border: 2px solid #666 !important; padding: 0 .5em;" v-if="!perguntas[perguntaatualidx].mascara" v-model="perguntas[perguntaatualidx].valor"/>
                   <input style="border-radius: 7px !important; border: 2px solid #666 !important; padding: 0 .5em;" v-if="perguntas[perguntaatualidx].mascara" 
                  v-mask="perguntas[perguntaatualidx].mascara === 'cnpj' 
                  ? '###.###.####/##' 
                  :(perguntas[perguntaatualidx].mascara === 'cpf'?'###.###.###-##'
                  :(perguntas[perguntaatualidx].mascara === 'telefone'?'(##) #-####-####'
                  :(perguntas[perguntaatualidx].mascara === 'data' ? '##/##/####' : null)))" v-model="perguntas[perguntaatualidx].valor"/>
                </div>
            </div>
        </div>
        <div v-if="perguntas[perguntaatualidx].respostadata">
          <div class="box minheight pergunta data" v-if="!movimento">
              <div class="field">
                  <p>{{perguntas[perguntaatualidx].pergunta}}</p>
                  <span class="respostadata-wrapper-inputs">
                    <v-text-field
                      label="Data"
                      placeholder="Insira a data"
                      outlined
                      v-model="perguntas[perguntaatualidx].valor"
                      name="data"
                      type="date"
                      dense
                      hide-details=""
                      class="input-date"
                    ></v-text-field>
                    <v-select
                      class="input-hour"
                      :items="horarios"
                      label="Horário"
                      name="horario"
                      v-model="perguntas[perguntaatualidx].horario"
                      outlined
                      dense
                      hide-details
                    ></v-select>
                  </span>

                </div>
          </div>
          <div class="box minheight pergunta data" v-if="movimento">
              <div class="field">
                  <p>{{perguntas[perguntaatualidx].pergunta}}</p>
                  <span class="respostadata-wrapper-inputs">
                    <v-text-field
                      label="Data"
                      placeholder="Insira a data"
                      outlined
                      v-model="perguntas[perguntaatualidx].valor"
                      name="data"
                      type="date"
                      dense
                      hide-details=""
                      class="input-date"
                    ></v-text-field>
                    <v-select
                      class="input-hour"
                      :items="horarios"
                      label="Horário"
                      name="horario"
                      v-model="perguntas[perguntaatualidx].horario"
                      outlined
                      dense
                      hide-details
                    ></v-select>
                  </span>

                </div>
          </div>
        </div>
         <!-- <div v-if="perguntas[perguntaatualidx].respostasfechadas && perguntas[perguntaatualidx].respostasfechadas.listaresposta.length > 0">
            <div class="box pergunta fechada">
                <div class="field">
                  <p>{{perguntas[perguntaatualidx].pergunta}}</p>
                  <div v-for="opcao, idx in perguntas[perguntaatualidx].respostasfechadas" :key="idx" class="wrapper-opcao">
                    <input type="checkbox" :disabled="perguntas[perguntaatualidx].respondida"  v-model="perguntas[index].respostasfechadas[idx].valor" @change="limparOpcoes(index, opcao)"/>
                    <label>{{opcao.texto}}</label>
                  </div>
                  <button type="button" class="ok" @click="finalizarPergunta(index, pergunta)" v-if="!perguntas[perguntaatualidx].respondida">ok</button>
                  <button type="button" class="refazer" @click="refazerPergunta(index, pergunta)" v-if="perguntas[perguntaatualidx].respondida">refazer</button>
                </div>
            </div>
          </div> -->
      </span>
      <div v-if="etapa && etapa.gadget === null" class="box">
        <!-- {{etapa.tituloemail}} -->
        <br/>
        {{etapa.texto}}
      </div>
      <div v-if="etapa !== null" class="box wrapper-action">
        <button type="button" class="btn-envio" @click="anterior" v-if="perguntaatualidx > 0">anterior</button>
        <button type="button" class="btn-envio" @click="proxima" v-if="perguntas && perguntas.length > 0 && !finalizaretapa">proxima</button>
        <button type="button" class="btn-envio" @click="submitForm" v-if="finalizaretapa || etapa.gadget && etapa.gadget.idtipo === 1">finalizar etapa</button>
        <button type="button" class="btn-envio" @click="submitForm" v-if="perguntas && perguntas.length == 1">finalizar etapa</button>
        <button type="button" class="btn-envio" @click="submitForm" v-if="!etapa.gadget">finalizar etapa</button>
      </div>
      <div v-if="etapa === null" class="box">
        <div style="display: flex; flex-direction: column; justify-content: center; width: 100%; align-items: center;">
          <p v-if="loading">Carregando...</p>
          <p v-if="!loading">Todas as etapas concluídas com sucesso!</p>
          <button  v-if="!loading" style="background: #1377F2; color: white; border-radius: 10px; padding: 5px; width: 300px; margin: 10px 0 0 0;" type="button" class="btn-envio" @click="() => $router.push(`/lista/implantacao/${implantacao.id}`)" >Voltar para implantações</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImplantacaoServico from '@/services/implantacao.service.js'
import MovimentoServico from '@/services/movimento.service.js'
import Assinatura from '@/components/Assinatura/index.vue'
import {alertar} from '@/services/notificacao.service.js'
import AtividadeServico from '@/services/atividade.service.js'
import dayjs from 'dayjs'
export default {
  components:{
    Assinatura
  },
  data(){
    return{
      id: null,
      implantacao:{},
      etapa: null,
      perguntas: [],
      assinaturaetapa: null,
      movimento: null,
      verassinatura: false,
      perguntaatualidx: 0,
      perguntasextrasatuais: [],
      horarios: [],
      horario: null,
      finalizaretapa: false,
      loading: true,
    }
  },
  computed:{
    classeEtapa(){
      return (etapaatual) => {
        let classe;
        if(this.etapa){
          etapaatual.nome === this.etapa.nome ? classe+=' selecionada' : null
          etapaatual.concluida ? classe+=' concluida' : null
        }
        return classe
      }
    }
  },
  methods: {

    async registrarMovimento(){
      const {  idcliente } = this.$route.params
    let movimento = {
      idusuario: localStorage.getItem('idusuario'),
      tipomovimento: 11,
      idcliente: idcliente,
      datahoramovimento: dayjs().format(),
      descricao: this.etapa.nome
    }

    console.log(movimento)
    console.log(this.etapa)
    await AtividadeServico.registrarMovimentoPorCliente(movimento).then(async () => { 
      console.log('ok')
    }).catch(error=>{
        console.log(error)
    })


},

    proxima(){
      const pergunta = this.perguntas[this.perguntaatualidx]
      if(this.perguntaatualidx === this.perguntas.length - 1) {
        // this.perguntaatualidx -= 1
        this.loading = true;
        this.submitForm()
        return this.finalizaretapa = true
      }
      //pular verificacao
      if(pergunta && !pergunta.obrigatoria && !pergunta.respostasfechadas){
        this.perguntaatualidx++
        if(this.perguntas[this.perguntaatualidx].respostadata || this.perguntas[this.perguntaatualidx].respostaaberta){
          if(this.perguntaatualidx === this.perguntas.length -1){
            this.finalizaretapa = true
          }
        }
        return
      }

      //verificar se todas as perguntas tem resposta
      if(pergunta && (pergunta.respostadata || pergunta.respostaaberta)){
        if(!pergunta.valor || pergunta.valor.trim() === ''){
          return 
        }
      }else if(pergunta && (!pergunta.respostadata && !pergunta.respostaaberta)){
        const resposta = pergunta.respostasfechadas.listaresposta.filter(el => el.valor === true)[0]
        if(!resposta){
          return
        }
      }


      //aplicar transicao ou perguntas extras
      if(pergunta &&  !pergunta.respostadata && !pergunta.respostaaberta){
        const resposta = pergunta.respostasfechadas.listaresposta.filter(el => el.valor === true)[0]
        if(resposta.perguntasextras && resposta.perguntasextras.length > 0){
          //inserir as perguntas extras  ou incremetar valor do perguntaatualidx
            if(this.perguntasextrasatuais && this.perguntasextrasatuais.length > 0){
              //validar perguntas extras
              //ver se tem transicao
              const transicoes = this.perguntas[this.perguntaatualidx].transicoes
              //filtra transicao pelo seu atributo condicao checando se a resposta.resposta esta nela
              const transicao = transicoes && transicoes.filter(el => el.condicao.includes(resposta.resposta))[0]
              let incremento
              if(transicao){
                this.perguntas.map((pergunta, idx) => {
                  if(pergunta.id === transicao.idgadgetproximapergunta){
                    incremento = idx
                  }
                })
                this.perguntaatualidx = incremento
                if(this.perguntaatualidx === this.perguntas.length - 1){
                  this.finalizaretapa = true
                }
                return
              }
              this.perguntaatualidx++
              if(this.perguntaatualidx === this.perguntas.length - 1){
                this.finalizaretapa = true
              }
            }else{
              this.perguntasextrasatuais = resposta.perguntasextras
              if(this.perguntaatualidx === this.perguntas.length - 1){
                this.finalizaretapa = true
              }
              return
            }
        }else{
          //ver se tem transicao
          const transicoes = this.perguntas[this.perguntaatualidx].transicoes
          //filtra transicao pelo seu atributo condicao checando se a resposta.resposta esta nela
          const transicao = transicoes && transicoes.filter(el => el.condicao.includes(resposta.resposta))[0]
          let incremento
          if(transicao){
            this.perguntas.map((pergunta, idx) => {
              if(pergunta.id === transicao.idgadgetproximapergunta){
                incremento = idx
              }
            })
            this.perguntaatualidx = incremento
            return
          }
          this.perguntaatualidx++
          if(this.perguntaatualidx === this.perguntas.length - 1){
            this.finalizaretapa = true
          }
        }
      }


      //verificar se a proxima pergunta é fechada, se tem pergunta extra se this.perguntasextrasautais esta preenchia
      //e se for a ultima etapa colocar this.finalizaretapa como true
      if(!this.perguntas[this.perguntaatualidx].respostadata && !this.perguntas[this.perguntaatualidx].respostaaberta){
        const resposta = this.perguntas[this.perguntaatualidx].respostasfechadas.listaresposta.filter(el => el.valor === true)[0]
        if(resposta){
          if(resposta.perguntasextras 
            && resposta.perguntasextras.length > 0 
            && this.perguntaatualidx === this.perguntas.length -1
            &&this.perguntasextrasatuais && this.perguntasextrasatuais.length > 0){
            this.finalizaretapa = true
          }
        }
      }

      //verificar se a proxima etapa é a ultima e se a pergunta for do tipo aberta ou data
      if(this.perguntas[this.perguntaatualidx].respostadata || this.perguntas[this.perguntaatualidx].respostaaberta){
        if(this.perguntaatualidx === this.perguntas.length -1){
          this.finalizaretapa = true
        }
      }




      //incrementar a variavel this.perguntaatualidx
      if(this.perguntaatualidx !== this.perguntas.length - 1){
        this.perguntaatualidx++
      }


    },
    anterior(){
      if(this.perguntaatualidx === this.perguntas.length - 1){
        this.finalizaretapa = false
      }
      if(this.perguntaatualidx !== 0){
        this.perguntaatualidx--
      }
    },
    toLeft(){
      document.getElementById('slider').scrollLeft -= document.getElementById('slider').getBoundingClientRect().width/3
    },
    toRight(){
      document.getElementById('slider').scrollLeft += document.getElementById('slider').getBoundingClientRect().width/3
    },
    async submitForm(e){
      if(e) {
        e.preventDefault()
      }

      await this.registrarMovimento()
      if(this.perguntas){
        const pergunta = this.perguntas[this.perguntaatualidx]

        //pular verificacao
        // if(!pergunta.obrigatoria && !pergunta.respostasfechadas){
        //   this.perguntaatualidx++
        //   if(this.perguntas[this.perguntaatualidx].respostadata || this.perguntas[this.perguntaatualidx].respostaaberta){
        //     if(this.perguntaatualidx === this.perguntas.length -1){
        //       this.finalizaretapa = true
        //     }
        //   }
        //   return
        // }

        //verificar se todas as perguntas tem resposta
        if(pergunta.respostadata || pergunta.respostaaberta){
          if(!pergunta.valor || pergunta.valor.trim() === ''){
            return 
          }
        }else if(!pergunta.respostadata && !pergunta.respostaaberta){
          const resposta = pergunta.respostasfechadas.listaresposta.filter(el => el.valor === true)[0]
          if(!resposta){
            return
          }
        }
      
        try{
          if(this.movimento){
            if(this.etapa.gadget.idtipo === 2){
              console.log('entrou aqui')
                const movimento = {
                  id: this.movimento.id,
                idimplantacao: this.implantacao.id,
                idetapa: this.etapa.id,
                dataconclusao: new Date().toISOString(),
                idusuario: localStorage.getItem('idusuario'),
                dataprimeiroemail: "2022-01-13 10:00:00",
                dataultimoemail: null,
                dataultimoacessoetapa: new Date().toISOString(),
                respostas: this.perguntas.map(pergunta => {
                  console.log('pergunta', pergunta)
                  if(pergunta.respostaaberta){
                    return {
                      id: pergunta.idresposta,
                      idpergunta: pergunta.id,
                      resposta: pergunta.valor,
                    }
                  }else if(pergunta.respostadata){
                    return {
                      id: pergunta.idresposta,
                      idpergunta: pergunta.id,
                      resposta: pergunta.valor+'__'+pergunta.horario,
                      tipo:'data'
                    }
                  }else{
                    let resposta = pergunta.respostasfechadas.listaresposta.filter(el => el.valor === true)[0]
                    const extras = resposta.perguntasextras && resposta.perguntasextras.map(res => {
                      let retorno =  {
                        ...res,
                      respostavalor: res.respostavalor 
                      }
                      // delete retorno.valor // o campo valor é usad
                      return retorno
                    })
                    resposta.perguntasextras = extras
                    return{
                      id: pergunta.idresposta,
                      idpergunta: pergunta.id,
                      resposta: resposta
                    }
                  }
                })
              }
              
              await MovimentoServico.criar(movimento).then(async res => {
                if(res.status === 200){
                  alertar('success', '=)', 'Etapa respondida com successo!')
                   this.$router.go()
                }
              }).catch(e => {
                console.log(e)
                alertar('warning', '=)', 'Falha na resolução da etapa!')
              })
            }
            if(this.etapa.gadget.idtipo === 1){
                const movimento = {
                  id: this.movimento.id,
                idimplantacao: this.implantacao.id,
                idetapa: this.etapa.id,
                dataconclusao: new Date().toISOString(),
                idusuario: localStorage.getItem('idusuario'),
                dataprimeiroemail: "2022-01-13 10:00:00",
                dataultimoemail: null,
                dataultimoacessoetapa: new Date().toISOString(),
                assinaturaetapa: this.assinaturaetapa
              }
              
              await MovimentoServico.criar(movimento).then(res => {
                if(res.status === 200){
                  alertar('success', '=)', 'Etapa respondida com successo!')
                  this.$router.go()
                }
              }).catch(e => {
                console.log(e)
                alertar('warning', '=)', 'Falha na resolução da etapa!')
              })
            }
        }else{
          if(this.etapa.gadget.idtipo === 2){
              const movimento = {
              idimplantacao: this.implantacao.id,
              idetapa: this.etapa.id,
              dataconclusao: new Date().toISOString(),
              idusuario: localStorage.getItem('idusuario'),
              dataprimeiroemail: new Date().toISOString(),
              dataultimoemail: null,
              dataultimoacessoetapa: new Date().toISOString(),
              respostas: this.perguntas.map(pergunta => {
                if(pergunta.respostaaberta){
                  return {
                    idpergunta: pergunta.id,
                    resposta: pergunta.valor,
                  }
                }else if(pergunta.respostadata){
                  return {
                    idpergunta: pergunta.id,
                    resposta: pergunta.valor+'__'+pergunta.horario,
                  }
                }else{
                  let resposta = pergunta.respostasfechadas.listaresposta.filter(el => el.valor === true)[0]
                  console.log(resposta)
                  if(resposta) {
                    
                    const extras = resposta.perguntasextras.map(res => {
                      let retorno =  {
                        ...res,
                      respostavalor: res.valor 
                      }
                      delete retorno.valor
                      return retorno
                    })
                    resposta.perguntasextras = extras
                    return{
                      idpergunta: pergunta.id,
                      resposta,
                    }
                  }
                }
              }
              )
            }
           
            await MovimentoServico.criar(movimento).then(res => {
              if(res.status === 200){
                alertar('success', '=)', 'Etapa respondida com successo!')
                this.$router.go()
              }
            }).catch(e => {
              console.log(e)
              alertar('warning', '=)', 'Falha na resolução da etapa!')
            })
          }
          if(this.etapa.gadget.idtipo === 1){
              const movimento = {
              idimplantacao: this.implantacao.id,
              idetapa: this.etapa.id,
              dataconclusao: new Date().toISOString(),
              idusuario: localStorage.getItem('idusuario'),
              dataprimeiroemail: "2022-01-13 10:00:00",
              dataultimoemail: null,
              dataultimoacessoetapa: new Date().toISOString(),
              assinaturaetapa: this.assinaturaetapa
            }
        
            await MovimentoServico.criar(movimento).then(res => {
              if(res.status === 200){
                alertar('success', '=)', 'Etapa respondida com successo!')
                this.$router.go()
              }
            }).catch(e => {
              console.log(e)
              alertar('warning', '=)', 'Falha na resolução da etapa!')
            })
          }
        }
        this.selecionarEtapa(this.etapa.id)
        }catch(e){
          console.log('erroe subtmit', e)
        }
      }else{
         const movimento = {
              idimplantacao: this.implantacao.id,
              idetapa: this.etapa.id,
              dataconclusao: new Date().toISOString(),
              idusuario: localStorage.getItem('idusuario'),
              dataprimeiroemail: new Date().toISOString(),
              dataultimoemail: null,
              dataultimoacessoetapa: new Date().toISOString(),
              respostas: null,
              movimentosemgadget: true
              }
          
        await MovimentoServico.criar(movimento).then(res => {
              if(res.status === 200){
                alertar('success', '=)', 'Etapa respondida com successo!')
                 this.$router.go()
              }
            }).catch(e => {
              console.log(e)
              alertar('warning', '=)', 'Falha na resolução da etapa!')
            })
      }
    },
    async requisitarPerguntas(){
      // const implantacao = await ImplantacaoServico.buscarFull({id: 12}).then(res => res.data)
      this.loading = true;
      const implantacao = await ImplantacaoServico.buscarFull({id: this.id}).then(res => res.data)
      this.implantacao = implantacao
      if(implantacao.etapaatual){
        const etapainicial = implantacao.etapas.filter(el => el.concluida === false)
        this.selecionarEtapa(etapainicial[0].id)
      } else {
        this.loading = false;
      }
    },
    selecionarEtapa(id){
      if(this.etapa && this.etapa.id === id){
        this.etapa = null
        this.perguntas = null
        this.perguntasextrasatuais = []
        this.perguntaatualidx = 0
        this.loading = false;
      }else{
        this.perguntaatualidx = 0
        this.perguntasextrasatuais = []
        this.perguntas = null
        const etapaatual = this.implantacao.etapas.filter(el => el.id === id)[0]
        this.etapa = etapaatual
        this.loading = false;
        
        //criar perguntas inicias (todas aqulas que nao tem trancisao e nao estao em transicoes)
        if(etapaatual.gadget && etapaatual.gadget.idtipo === 2){
            this.perguntas = etapaatual.gadget.perguntas.filter(el => el.perguntaextra !== true)
            this.perguntas = this.perguntas.map(pergunta => {
              if(pergunta.respostasfechadas){
                let respostasfechadas = JSON.parse(pergunta.respostasfechadas)
                if(respostasfechadas){
                  let listaresposta = respostasfechadas.listaresposta.map(resposta => ({...resposta, valor: false}))
                  respostasfechadas['listaresposta']=listaresposta
                }
                return {
                ...pergunta,
                respostasfechadas: respostasfechadas            
                }
              }
              if(pergunta.respostaaberta){
                return {
                  ...pergunta,
                }
              }
              if(pergunta.respostadata){
                return {
                  ...pergunta,
                  horario: null,
                }
              }
            })
          }
      }
    },
    limparOpcoes(indexpergunta, opcao){
      let alterarpara = !this.perguntas[indexpergunta].respostasfechadas.listaresposta.filter(el => el.resposta === opcao.resposta)[0].valor
      if(alterarpara){
        this.perguntas[indexpergunta].respostasfechadas.listaresposta.map((op,idx) => {
          if(op.resposta !== opcao.resposta){
            this.perguntas[indexpergunta].respostasfechadas.listaresposta[idx].valor = false
          }
        })
      }else{
        this.perguntas[indexpergunta].respostasfechadas.listaresposta.map((op,idx) => {
          if(op.resposta !== opcao.resposta){
            this.perguntas[indexpergunta].respostasfechadas.listaresposta[idx].valor = alterarpara
          }
        })
      }
      //remover peguntas extras caso a opção anterior as tivesse
      this.perguntasextrasatuais &&  this.perguntasextrasatuais.length > 0 ? this.perguntasextrasatuais = [] : null
      //aleterar this.finalizaretapa = false se estiver na ultima pergunta
      if(this.perguntaatualidx === this.perguntas.length - 1){
        this.finalizaretapa = false
      }
    },
    limparOpcoesPergutasExtras(indexpergunta, opcao){
      let alterarpara = !this.perguntasextrasatuais[indexpergunta].respostasfechadas.listaresposta.filter(el => el.resp === opcao.resp)[0].valor
      if(alterarpara){
        this.perguntasextrasatuais[indexpergunta].respostasfechadas.listaresposta.map((op,idx) => {
          if(op.resp !== opcao.resp){
            this.perguntasextrasatuais[indexpergunta].respostasfechadas.listaresposta[idx].valor = false
          }
        })
      }else{
        this.perguntasextrasatuais[indexpergunta].respostasfechadas.listaresposta.map((op,idx) => {
          if(op.resp !== opcao.resp){
            this.perguntasextrasatuais[indexpergunta].respostasfechadas.listaresposta[idx].valor = alterarpara
          }
        })
      }
    },
    validacao(){
      let validado = []
      let objretorno;
      if(this.etapa.gadget.idtipo === 2){
        this.perguntas.map(pergunta => {
          if(!pergunta.respondida){
            validado.push(false)
          }
        })
      }
      if(this.etapa.gadget.idtipo === 1){
        if(!this.assinaturaetapa){
          validado.push(false)
        }
      }
      validado.includes(false) ? objretorno = false : objretorno = true
      return objretorno
    },
    async requisitarHorarios(){
      this.horarios = [
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
      ]
    }

  },
  watch: {
    etapa: async function(){
      await MovimentoServico.buscar({
        idetapa: this.etapa.id,
        idimplantacao: this.implantacao.id
      }).then(res => {
        const movimento = res.data[0]
        this.movimento = movimento
        if(movimento){
          if(movimento.respostas){
            for(let i =0; i< this.perguntas.length;i++){
              //preencher respostas
              const pergunta = this.perguntas[i]
              if(pergunta.respostasfechadas){        
                const objresposta = movimento.respostas.filter(el => el.idpergunta === pergunta.id)[0]
                let resposta = JSON.parse(objresposta.resposta)
  
                this.perguntas[i].respostasfechadas.listaresposta.map((resp, idxresp) => {
                  if(resp.id === resposta.id){
                    const extras = resposta.perguntasextras && resposta.perguntasextras.map(res => {
                      if(res.respostadata){
                        return res
                      }else{
                        res.valor = res.respostavalor
                        return res
                      }
                    })
                    this.perguntas[i].respostasfechadas.listaresposta[idxresp] = resposta
                    if(resposta.perguntasextras && resposta.perguntasextras.length >0){
                      this.perguntas[i].respostasfechadas.listaresposta[idxresp].perguntasextras = extras
                    }
                    this.perguntasextrasatuais = resposta.perguntasextras
                  }
                })
              }
              if(pergunta.respostaaberta){
                const objresposta = movimento.respostas.filter(el => el.idpergunta === pergunta.id)[0]
                this.perguntas[i].valor = objresposta.resposta
              }
              if(pergunta.respostadata){
                const objresposta = movimento.respostas.filter(el => el.idpergunta === pergunta.id)[0]
                this.perguntas[i].valor = objresposta.resposta.split('__')[0]
                this.perguntas[i].horario = objresposta.resposta.split('__')[1]
              }

            }
          }else{
            this.assinaturaetapa = this.movimento.assinaturaetapa
            this.verassinatura = true
          }
        }
      }).catch(e => {
        console.log('error', e)
      })
    },
    perguntasextrasatuais: function(){
      //inserir atributo valor na pergunta
      this.perguntasextrasatuais && this.perguntasextrasatuais.map(pergunta => {
        if(pergunta.respostaaberta){
          this.perguntas[this.perguntaatualidx].respostasfechadas && this.perguntas[this.perguntaatualidx].respostasfechadas.listaresposta.map((resposta, idxresposta) => {
            if(resposta.perguntasextras && resposta.perguntasextras.length > 0){
              resposta.perguntasextras.map((pergextra, pergextraidx) => {
                if(pergextra.id === pergunta.id){
                  this.perguntas[this.perguntaatualidx].respostasfechadas.listaresposta[idxresposta].perguntasextras[pergextraidx]['valor'] = null
                }
              })
            }
          })
        }
      })
    }

  },
  async mounted(){
    const { id } = this.$route.params
    this.id = id
    await this.requisitarHorarios()
    await this.requisitarPerguntas()

    
  }
}
</script>

<style lang="scss" scoped>
.respostadata-wrapper-inputs{
  display: flex;
  align-items: center;
  margin-top: 1em;
  width: 50%;
  justify-content: space-between;
  .input-date{
    margin-right: 1em;
  }
  
  @media screen and (max-width:768px) {
    flex-direction: column; 
    width: 100%;
    .input-date{
      margin-right: unset;
      width: 100%;
      margin-bottom: 1em;
    }   
    .input-hour{
      width: 100%;
    }   
  }
}
.slider-controls{
  position: sticky;
  left: 0;
  display: flex;
  button:nth-child(1){
    margin-right: .5em;
  }
  margin-right: 1em;
  background: rgb(240, 235, 248);
  padding: .5em 1em;
  border-radius: .5em;
}
.wrapper-action{
  display: flex;
  align-items: center;
  justify-content: center;
  >button[type="button"]{
    padding: .7em 1em;
    line-height: 1.5;
    color: white;
    font-weight: bold;
    letter-spacing: 0.02em;
    background: #1377f2;
    border-radius: 15px;
    text-transform: capitalize;
    width: 100%;
    margin: 0 .5em;
  }
}
.box{
  border: 1px solid #dadce0;
  border-radius: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  background-color: #f9f9f2;
  position: relative;
  margin-bottom: .7125em;
  transition: box-shadow 280ms cubic-bezier(0.4,0,0.2,1);
  -webkit-transition: box-shadow 280ms cubic-bezier(0.4,0,0.2,1);
  padding: 1em;
  &:hover{
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
    cursor: pointer;
  }

  &.header{
    min-height: 5em;
    align-items: flex-start;
    h1{
      font-size: 28px;
      text-transform: capitalize;
    }
  }

  &.contrato{
    flex-direction: column;
    overflow-y: scroll;
    max-height: 25em;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.assinatura{
    height: 14em;
  }
  &.minheight{
    min-height: 15em;
  }
}
.pergunta{
  &.fechada{

  }
  &.aberta{

  }
  &.data{

  }
}

.field{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  >p{
    font-size: 16px;
    letter-spacing: .1px;
    line-height: 24px;
    color: #202124;
    font-weight: 600;
    width: 100%;
    word-break: break-word;
    text-align: start;
    margin-bottom: 1em;
  }
  .wrapper-opcao{
    margin: .2em 0 ;
    >label{
      font-family: Roboto,Arial,sans-serif;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: .2px;
      line-height: 20px;
      color: #202124;
      min-width: 0%;
      text-transform: capitalize;
    }
    >input[type="checkbox"]{
      margin-right: .5em;
    }
  }
  >button[type="button"]{
    margin-top: 1em;
    padding: .5em 1.5em;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 20px;
    border: none;
    &.ok{
      background: green;
      color: #f9f9f2;
    }
    &.refazer{
      background: #dadce0;
      color: #aaa;
      transition: color .1s ease-in;
      &:hover{
        background: #1377f2;
        color: #f9f9f2;
      }
    }

  }
  >textarea{
    width: 100%;
    outline: none;
    border: 1px solid black;
    border-radius: 30px;
    resize: vertical;
    height: 100%;
  }
  >input{
    border: 1px solid black;
    border-radius: 15px;
  }
}
.container{
  width: 770px;
  margin: 0 auto;
  @media screen and (max-width: 768px){
    width: 90vw;
  }
}

.header{
  margin: 1em 0 .5em 0;
  overflow: hidden;
  ul{
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    width: 100%;
    display: flex;
    align-items: center;

    li {
      padding: .5em 1em;
      border-radius: 30px;
      font-weight: bold;
      background: #1377f2;
      height: 3em;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover{
        cursor: pointer;
      }
      flex-shrink: 0;
      width: fit-content;
      margin: 0 .2em;
      color: white;
      text-transform: capitalize;
      &.selecionada{
        font-weight: bold;
        background: #f9f9f2;
        border: 3px solid #1377f2;
        color: #1377f2;
      }
    }
  }
}

.ver-assinatura{
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  >button[type="button"]{
    color: #1377f2;
    box-shadow:  4px 4px 10px #d9d9d9;
    border-radius: 1em;
    margin-left: 1em;
    padding: 1em;
    position: absolute;
    right: 5%;
    top: 5%;
  }
}
//css indesejados
.col-12, .col-md-6, .col-sm-6{
  padding: unset;
}
</style>